import React, { useContext, useEffect, useState } from 'react'
import { useNavigate,useLocation } from 'react-router-dom'
import propertyContext from '../../../context/PropertyContext'
import Alert from '../../Alert'
import Navbar from '../../Header/Navbar'
import Rflate from './Rflate'
import RflateFilter from './RflateFilter'
import Footer from '../../Footer/Footer'
import Loader from '../../loader'

const RflatList = () => {
  let history = useNavigate();
  const mylocation = useLocation();
  const context = useContext(propertyContext);
  const { host } = context;
  const [showLoader,setShowLoader] = useState(true)
  const [RfmData, setData] = useState([]);
  const [rr, setrr] = useState([])
  const [showAlert, setShowAlert] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // const getCurrentPageData = () => {
  //   const startIndex = (currentPage - 1) * itemsPerPage;
  //   const endIndex = startIndex + itemsPerPage;
  //   const paginatedData = RfmData.slice(startIndex, endIndex);
  //   return {
  //     data: paginatedData,
  //     startIndex,
  //     endIndex
  //   };
  // };
  // const { data, startIndex, endIndex } = getCurrentPageData();
  
  const handlePageChange = (pageNum) => {
    setCurrentPage(pageNum);
  };

  const handleAlert = () => {
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000);
  };
  useEffect(() => {
    setShowLoader(true)
    async function listrrprop() {
      const responce = await fetch(`${host}/property/getrfm`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // "auth-token": localStorage.getItem("token"),
        },
      });
      const resdata = await responce.json();
    
      if(localStorage.getItem("userDetail")){
        const userId = JSON.parse(localStorage.getItem('userDetail')).user._id;
        const newData = resdata.filter((curEle)=>{
          return curEle.userid !== userId
        })
      
      setData(newData);
      setrr(newData)
      setShowLoader(false)
      }else{
        setData(resdata);
        setrr(resdata)
        setShowLoader(false)
      }
    }
    listrrprop()
  }, []);

  function handleFilter(value) {
    console.log(value);
    if (
      value.room.length === 0 &&
      value.sex.length === 0 &&
      value.prop.length === 0 &&
      value.floor.length === 0 &&
      value.furnish.length === 0 &&
       value.veg.length === 0 &&
       value.park.length === 0 &&
       value.bath.length === 0 &&
       value.price === 2000
    ) {
      setData(rr);
    } else {
      console.log(value.range);

      const filterData = rr.filter((property) => {
        if(value.price === 2000){
        console.log("Price isn't Change");
        return (
          value.room.includes(property.rfm_detail_room_type) ||
          value.sex.includes(property.rfm_detail_tenent_type) ||
          value.prop.includes(property.rfm_detail_app_type) ||
          value.floor.includes(property.rfm_detail_total_floor) ||
          value.furnish.includes(property.rfm_detail_furnishing) ||
          value.park.includes(property.rfm_detail_parking) ||
          value.bath.includes(property.rfm_detail_bathroom)
        );}
        else if(value.price !== 2000 && value.room.length === 0 && value.sex.length === 0 && value.prop.length === 0 && value.floor.length === 0 && value.furnish.length === 0 &&  value.park.length === 0 && value.bath.length === 0 ){
         {console.log("Only Price Change")
           return( parseInt(property.rfm_rental_detail_rent) < value.price)}
        }
        else{
          console.log('Price and Other Change') 
          return (
            (value.room.includes(property.rfm_detail_room_type) ||
            value.sex.includes(property.rfm_detail_tenent_type) ||
            value.prop.includes(property.rfm_detail_app_type) ||
            value.floor.includes(property.rfm_detail_total_floor) ||
            value.furnish.includes(property.rfm_detail_furnishing) ||
            value.park.includes(property.rfm_detail_parking) ||
            value.bath.includes(property.rfm_detail_bathroom) ) &&
            (parseInt(property.rfm_rental_detail_rent) < value.price)
          )}
        
      });
      setData(filterData);

      console.log(filterData);
    }
  }
  return (
    <div id="main-wrapper">

      <Navbar />
      {showAlert && <Alert msg="Please Login Before!!" />}
      {showLoader && <Loader />}
      <div className="clearfix" />
      <section className="gray pt-4">
        <div className="container">
          <div className="row m-0">
            <div className="short_wraping mb-2">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-6 col-sm-12  col-sm-6">
                  <ul className="shorting_grid">

                    <li className="list-inline-item"><a><span className="ti-map-lt" />Residential Flatmates</a></li>
                  </ul>
                </div>
                <div className="col-lg-9 col-md-12 col-sm-12 order-lg-2 order-md-3 elco_bor col-sm-12">
                  <div className="shorting_pagination">
                    <div className="shorting_pagination_laft">
                    {/* <h5>Showing {startIndex+1}-{endIndex > RfmData.length ? RfmData.length : endIndex} of {RfmData.length} results</h5> */}
                    </div>
                    {/* <div className="shorting_pagination_right">
                      <ul>
                        <li><a href="javascript:void(0);" className="active">1</a></li>
                        <li><a href="javascript:void(0);">2</a></li>
                        <li><a href="javascript:void(0);">3</a></li>
                        <li><a href="javascript:void(0);">4</a></li>
                        <li><a href="javascript:void(0);">5</a></li>
                        <li><a href="javascript:void(0);">6</a></li>
                      </ul>
                    </div> */}
                    
                  </div>
                </div>

              </div>
            </div>
          </div>
      {mylocation.state?.area && (<p className='short_wraping mb-2'>{mylocation.state?.area}</p>) } 

          {/* <div className="row">

            <RflateFilter handleFilter={handleFilter}/>
            <div className="col-lg-8 col-md-12 col-sm-12" style={{height:'60rem',overflowY:'auto', WebkitOverflowScrolling: 'touch', '-ms-overflow-style': 'none', scrollbarWidth: 'none'}}>{
            RfmData.map((property) => {
              return (
                <Rflate property={property} key={property._id} onAlert={handleAlert} />
              )
            })
            }</div>


          </div> */}
          <div className="row">
      <RflateFilter handleFilter={handleFilter} />
      <div
        className="col-lg-8 col-md-12 col-sm-12"
        >
        {
          RfmData.map((property) => {
            return <Rflate property={property} key={property._id} onAlert={handleAlert} />;
          })
        }
       
      </div>
    </div>
          <div className="row align-items-center">
                <div className="col-lg-3 col-md-6 col-sm-12  col-sm-6">
                  <ul className="shorting_grid">

                    {/* <li className="list-inline-item"><a><span className="ti-map-lt" />Residential Flatmates</a></li> */}
                  </ul>
                </div>
                <div className="col-lg-9 col-md-12 col-sm-12 order-lg-2 order-md-3 elco_bor col-sm-12">
                  <div className="shorting_pagination">
                    <div className="shorting_pagination_laft">
                    {/* <h5>Showing {startIndex+1}-{endIndex > RfmData.length ? RfmData.length : endIndex} of {RfmData.length} results</h5> */}
                    </div>
                    <div className="shorting_pagination_right">
                    {/* <ul style={{cursor:"pointer"}}>
                      {Array(Math.ceil(RfmData.length / itemsPerPage)).fill().map((_, i) => (
                        <li key={i}>
                          <a className={currentPage === i + 1 ? 'active' : ''} onClick={() => handlePageChange(i + 1)}>
                            {i + 1}
                          </a>
                        </li>
                      ))}
                    </ul> */}
                    </div>
                  </div>
                </div>

              </div>
        </div>
      </section>
     <Footer/>

    </div>

  )
}

export default RflatList