import React from 'react'
import Layout from './Layout'

const HomeAdmin = () => {
  return (
    <>
        <Layout>
          <h2 style={{textAlign:"center"}}>Welcome to the Admin page!</h2>
          {/* <h5 style={{textAlign:"center"}}>This is the Starting page of the Admin.</h5> */}
        </Layout>
    </>
  )
}

export default HomeAdmin
